.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.loader-dot1,
.loader-dot2,
.loader-dot3 {
    width: 8px;
    height: 8px;
    background-color: #1b6820;
    animation: loader-animation 0.8s infinite ease-in-out;
}

.loader-dot2 {
  animation-delay: 0.2s;
}

.loader-dot3 {
  animation-delay: 0.4s;
}

@keyframes loader-animation {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
