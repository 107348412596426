




@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }

  50% {
    background-position: 100% 100%;
  }

  100% {
    background-position: 0% 0%;
  }
}

/* Style for the chat header */
.chat-header {
  padding: 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d8d8d8;
}




.chat-header-buttons{
  position: absolute;
  right: 80px;
}


.info-modal{
  backdrop-filter: blur(10px);
}

.closeButton{
  color: red;
  background: #ff00001f;
  padding: 10px;
  border-radius: 10px;
}



.reset-button{
  background-color: #ffffff8c;
  display: table-cell;
  margin: 8px;
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
  display: inline-block;
  max-width: 80%;
  border-radius: 8px;
  word-break: break-word;

}

.info-button{
  background-color: #ffffff8c;
  display: table-cell;
  margin: 8px;
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
  display: inline-block;
  max-width: 80%;
  border-radius: 8px;
  word-break: break-word;

}



@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&family=Josefin+Sans&family=Jost&family=PT+Serif&display=swap');

/* Style for the chat container */
.chat-container {
  width: auto;
  margin: 50px;
  box-shadow: 6px 6px 20px 0px rgb(221 221 221 / 89%);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90vh;
  background: linear-gradient(to bottom right, #e6646526, #9198e53d, #3cba9f3b, #f7b73333);
  background-size: 400% 400%;
  animation: gradientAnimation 20s ease infinite;
}

/* Center the whole window horizontally and vertically */
html, body {
  height: 100vh;
  display: contents;
  justify-content: center;
  align-items: center;
  font-family: 'Josefin Sans', sans-serif;
}

/* Style for the chat header */
.chat-header {
  padding: 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d8d8d8;
}

.chat-header h2 {
  font-size: 18px;
  font-weight: bold;
  margin-left: 16px;
}

/* Style for the chat messages */
.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.chat-message {
  display: table-cell;
  margin: 8px;
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
  display: inline-block;
  max-width: 80%;
  border-radius: 8px;
  word-break: break-word;
}

.chat-message.user {
  background-color: #0084ff;
  color: white;
  align-self: flex-end;
}

.chat-message.bot {
  background-color: #ffffffc9;
  color: #222;
  align-self: flex-start;
}

/* Style for the chat input */
.chat-input {
  display: flex;
  align-items: center;
  border-top: 0px solid #d8d8d8;
}

.chat-input input {
  flex: 1;
  padding: 16px;
  border: none;
  border-bottom-left-radius: 10px;
  font-size: 16px;
  outline: none;
}

.chat-input button {
  padding: 16px;
  border: none;
  background-color: #0084ff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border-bottom-right-radius: 10px;
}

img {
  max-width: 70px;	
  border-radius: 50%;
}

/* Responsive styles */
@media (max-width: 768px) {
  .chat-container {
    width: 100%;
    max-width: 100%;
    margin: 0;
    height: 100vh;
  }

  .chat-header {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #d8d8d8;
  }
  
  .chat-header h2 {
    display: none;
  }
  
  .chat-header-buttons {
    display: flex;
    gap: 8px;
  }
    
    .chat-messages {
    padding: 12px;
    }
    
    .chat-input input {
    padding: 12px;
    }
    
    .chat-input button {
    padding: 12px;
    }
    
    html, body {
    margin: 0;
    padding: 0;
    }
}